import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null,
    reqContent: 'APPROVED',
    reqContentValue: 0,
    savedLab: [],
    visitedLabs: [], // Add this line for tracking visited labs
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        setReqContent: (state, action) => {
            state.reqContent = action.payload.reqContent;
        },
        setReqContentValue: (state, action) => {
            state.reqContentValue = action.payload.reqContentValue;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
            state.reqContent = 'APPROVED';
            state.reqContentValue = 0;
        },
        setSaveLab: (state, action) => {
            state.savedLab = [...state.savedLab, action.payload];
        },
        updateSaveLab: (state, action) => {
            state.savedLab = action.payload;
        },
        setVisitedLab: (state, action) => {
            // Ensure labId is not already in visitedLabs
            if (!state.visitedLabs.includes(action.payload)) {
                state.visitedLabs.push(action.payload);
            }
            // state.visitedLabs.push(action.payload);
        },
        clearVisitedLabs: (state) => {
            state.visitedLabs = [];
        },
    },
});

export const { setLogin, setLogout, setReqContent, setReqContentValue, setSaveLab, updateSaveLab, setVisitedLab, clearVisitedLabs } = authSlice.actions;
export default authSlice.reducer;
