export const initialState = {
    currPage: 1,
    error: false,
    newConsultingId: '',
    newConsulting: {
        RfqType: 'Consulting Service',
        UserInfo: {},
        Address: {},
        Description: '',
        UploadedDocs: {},
        Services: [],
    },
    defaults: {
        pageHeader: ["CONSULTING", "CONSULTING", "Submit Your Quote Request"],
        servicesArray: [
            "NRTL/CB Scheme Certification Support",
            "EMC Troubleshooting & Design Review",
            "Electrical Safety Test Plan Preparation",
            "FDA 510(k) Premarket Notification Regulatory Submission",
            "EMC Test Plan Preparation",
            "FDA De Novo Program Regulatory Submission",
            "Preliminary Design / Construction Review (PDR)",
            "FDA Pre-Submission Request for Feedback",
            "Prepare or Review Insulation/Isolation Diagram",
            "FDA 513(g) Request for Classification",
            "Risk Management File Review and Checklist Completion",
            "EU MDR Technical Documentation Assessment",
            "Software Lifecycle Document Review and Checklist Completion",
            "EU IVDR Technical Documentation Assessment",
            "Usability File Review and Checklist Completion",
            "UK Medical Device Technical Documentation Assessment",
            "Accompanying Documents Review and Checklist Completion",
            "UK IVD Technical Documentation Assessment",
            "NRTL Recognition for North America",
            "Global Market Access - Regulatory Requirements Definition",
            "Coexistence Risk Assessment & Test Plan",
            "Global Market Access - Radio Registration",
            "Cybersecurity Risk Assessment & Test Plan",
            "Global Market Access - Brazil INMETRO Certification",
            "Laboratory Findings Report Review & Remediation",
            "Training - Medical Device Industry Overview",
            "Training - Risk Management per ISO 14971:2019",
            "Training - Medical Electrical Standards including AIM 7351731",
            "Training - Software Lifecycle & FDA Requirements",
            "Performance Test Method Development & Validation",
            "Design Verification Support - Plans and Protocols",
            "Biological Evaluation Plans",
            "Formative Usability Study",
            "Summative Usability Study"
        ]
    },

}


const reducer = (state, action) => {
    // console.log("addLabReducer", state, action);
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_Consulting_ID':
            return {
                ...state,
                newConsultingId: state.payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                currPage: state.currPage + 1,
            }
        case 'UPDATE_PAGE':
            return {
                ...state,
                currPage: action.payload,
            }
        case 'SET_PAGE_BACK':
            return {
                ...state,
                currPage: state.currPage > 1 ? state.currPage - 1 : 1,
            }
        case 'UPDATE_PAGE_BACK':
            return {
                ...state,
                currPage: action.payload,
            }


        case 'SET_GEN_INFO':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    UserInfo: {
                        ...state.newConsulting.UserInfo,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'SET_ADDRESS':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    Address: {
                        ...state.newConsulting.Address,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'UPDATE_SERVICES':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    Services: [
                        ...state.newConsulting.Services,
                        action.payload,
                    ]
                },
            }
        case 'SET_SERVICES':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    Services: action.payload
                },
            }
        case 'SET_DESC':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    Description: action.payload
                },
            }

        case 'SET_STATE_COM_DOCS':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    UploadedDocs: {
                        ...state.newConsulting.UploadedDocs,
                        [action.payload.name]: {
                            ...state.newConsulting.UploadedDocs[action.payload.name],
                            [action.payload.fieldName]: action.payload.value
                        }
                    }
                },
            }

        case 'SET_EMAILS':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    Emails: action.payload,
                },
            }

        case 'SET_EMAILS_BOXES':
            return {
                ...state,
                newConsulting: {
                    ...state.newConsulting,
                    filledEmailsBox: action.payload,
                },
            }

        default:
            return state;
    }
}

export default reducer;