import { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from "./NPEReducer";
// Data layer
export const NPEStateContext = createContext();

// Provider
export const NPEStateProvider = (props) => {
    return (
        <NPEStateContext.Provider value={useReducer(reducer, initialState)} >
            {props.children}
        </NPEStateContext.Provider>
    )
}

// this is used inside the components
export const useNPEStateValue = () => useContext(NPEStateContext);