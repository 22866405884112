// for useReducer parameters
export const initialState = {
  userData: [],
  labData: {},
  query: {
    search: "",
    page: 1,
    sort: "Recommended",
    testingServices: [],
    productCategories: [],
    standards: [],
    neighborhoods: "",
    regions: "",
  },
  defaults: {
    regions: [
      "India",
      "U.S.A",
      "China",
      "Europe",
      "Asia",
      "Africa",
      "North America",
      "Austraila",
    ],
  },
  refresh: "",
  authMsg: "",
  isLoading: false,
  visitedLabs: [],
};

const reducer = (state, action) => {
  // console.log("reducer", state);
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_REQ_CONTENT":
      return {
        ...state,
        reqContent: action.payload,
      };
    case "SET_REFRESH":
      return {
        ...state,
        refresh: action.payload,
      };
    case "SET_AUTH_MSG":
      return {
        ...state,
        authMsg: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_IS_ADMIN":
      return {
        ...state,
        isAdmin: action.payload,
      };
    case "SET_LAB_DATA":
      return {
        ...state,
        labData: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_SORT_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          sort: action.payload,
        },
      };
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          search: action.payload,
          page: 1,
        },
      };
    case "SET_PAGE_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          page: action.payload,
        },
      };
    case "SET_REGIONS_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          regions: action.payload,
        },
      };
    case "SET_STANDARDS_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          standards: [...state.query.standards, action.payload],
        },
      };
    case "UPDATE_STANDARDS_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          standards: action.payload,
        },
      };
    case "SET_TESTING_SERVICES_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          testingServices: [...state.query.testingServices, action.payload],
        },
      };
    case "UPDATE_TESTING_SERVICES_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          testingServices: action.payload,
        },
      };
    case "SET_PRODUCT_CATEGORIES_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          productCategories: [...state.query.productCategories, action.payload],
        },
      };
    case "UPDATE_PRODUCT_CATEGORIES_QUERY":
      return {
        ...state,
        query: {
          ...state.query,
          productCategories: action.payload,
        },
      };
    case "SET_VISITED_LAB":
      return {
        ...state,
        visitedLabs: [...state.visitedLabs, action.payload],
      };
    case "CLEAR_VISITED_LABS":
      return {
        ...state,
        visitedLabs: [],
      };
    default:
      return state;
  }
};

export default reducer;
