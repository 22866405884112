export const initialState = {
    currPage: 1,
    error: false,
    newLab: {
        labInfo: {
            UserInfo: {
                name: '',
                email: '',
                countryCode: '',
                phone: '',
                company: '',
            },
            ContactNumber: {
                countryCode: '',
                phone: '',
            },
            LabName: "",
            Email: "",
            WebSite: "",
            Region: "",
            Address: {
                street: '',
                street1: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            },
            BusinessCategory: [],
            LabAccreditation: [],
        },
        labApprovals: {
            RegulatoryApprovals: [],
            RA_Markets: [],
            AR_CoveredMarkets: [],
            ConsultingServices: [],
            Consulting_CoveredMarkets: [],
        },
        Chambers: [],
        TestingServices: [],
        ProductCategories: [],
        TrainingSubjects: [],
    },
    otp: '',
    defaults: {
        approvals: [
            { first: 'FDA', second: 'CE' },
            { first: 'FCC', second: 'BIS/CRS Registration' },
            { first: 'UKCA', second: 'Type Approval' },
            { first: 'Global Market Access', second: 'MDR/IVDR' },
            { first: 'ISI/BIS Certification', second: 'BEE Registration' },
            { first: 'EPR Authorization', second: 'TEC Certification' },
        ],
        marketApprovals: [
            { first: 'AUSTRALIA', fflag: 'AU', second: 'SWITZERLAND', sflag: 'SZ' },
            { first: 'BRAZIL', fflag: 'BR', second: 'KOREA', sflag: 'KR' },
            { first: 'CANADA', fflag: 'CA', second: 'MEXICO', sflag: 'MX' },
            { first: 'CHINA', fflag: 'CN', second: 'SAUDI ARABIA', sflag: 'SA' },
            { first: 'DENMARK', fflag: 'DK', second: 'SINGAPORE', sflag: 'SG' },
            { first: 'EUROPEAN UNION', fflag: 'BR', second: 'JAPAN', sflag: 'JP' },
            { first: 'INDIA', fflag: 'IN', second: 'UNITED KINGDOM', sflag: 'GB' },
        ],
        consultingServices: [
            { first: 'EPR Authorization', second: 'TEC Certification' },
            { first: 'BEE Registration', second: 'ISI/BIS Registration' },
            { first: 'Type Approval Support', second: 'CE-Technical File Construction' },
            { first: 'WPC/ETA Approval', second: 'BIS/CRS Registration' },
            { first: 'Product Safety Testing', second: 'EMC/EMI/Radio Test Support' },
            { first: 'NRTL/CB Scheme Certification Support', second: 'EMC Troubleshooting & Design Review' },
            { first: 'Electrical Safety Test Plan Preparation', second: 'FDA 510(k) Premarket Notification Regulatory Submission' },
            { first: 'EMC Test Plan Preparation', second: 'FDA De Novo Program Regulatory Submission' },
            { first: 'Preliminary Design / Construction Review (PDR)', second: 'FDA Pre-Submission Request for Feedback' },
            { first: 'Prepare or Review Insulation/Isolation Diagram', second: 'FDA 513(g) Request for Classification' },
            { first: 'Risk Management File Review and Checklist Completion', second: 'EU MDR Technical Documentation Assessment' },
            { first: 'Software Lifecycle Document Review and Checklist Completion', second: 'EU IVDR Technical Documentation Assessment' },
            { first: 'Usability File Review and Checklist Completion', second: 'UK Medical Device Technical Documentation Assessment' },
            { first: 'Accompanying Documents Review and Checklist Completion', second: 'UK IVD Technical Documentation Assessment' },
            { first: 'NRTL Recognition for North America', second: 'Global Market Access - Regulatory Requirements Definition' },
            { first: 'Coexistence Risk Assessment & Test Plan', second: 'Global Market Access - Radio Registration' },
            { first: 'Cybersecurity Risk Assessment & Test Plan', second: 'Global Market Access - Brazil INMETRO Certification' },
            { first: 'Laboratory Findings Report Review & Remediation', second: 'Training - Medical Device Industry Overview' },
            { first: 'Training - Risk Management per ISO 14971:2019', second: 'Training - Medical Electrical Standards including AIM 7351731' },
            { first: 'Training - Software Lifecycle & FDA Requirements', second: 'Performance Test Method Development & Validation' },
            { first: 'Design Verification Support - Plans and Protocols', second: 'Biological Evaluation Plans' },
            { first: 'Formative Usability Study', second: 'Summative Usability Study' },
        ],
        trainingSubjects: ['PRODUCT SAFETY - IEC:61010-1', 'PRODUCT SAFETY - IEC:60601-1', 'EMC/EMI', 'FUNCTIONAL SAFETY', 'LASER SAFETY', 'SOFTWARE - IEC:62304-1', 'RISK MANAGEMENT', 'FDA REGISTRATION', 'CE REGISTRATION', 'GLOBAL MARKET ACCESS', 'RADIO', 'RoHS', 'REACH', 'Type Approval', 'Packaging Test',],

        testingServices: ['EMC', 'EMI-EMC Testing', 'Battery Testing', 'Environmental & Reliability Testing', 'RADIO', 'Product Safety Testing', 'IP Testing', 'Medical Device Testing', 'REACH', 'RoHS', 'Vibration & Shock Testing', 'Type Approval', 'Packaging Test'],
        pageHeader: ["Hello! Let’s start with your business information", "Great! Now Let’s add some business categories", "Great! Now Let’s add some business categories", "Great! Now Let’s add some business categories", "Great! Now Let’s add some business categories", "Great! Now Let’s add some business categories", "Great! Now Let’s add some business categories"],
        //    productCategories : ['Medical Electrical Devices', 'Battery', 'Household Appliances', 'Commercial and Light Industrial Machinery', 'IT Product & Audio/Video Equipment', 'Measurement Control & Laboratory Equipment', 'IVD'],
        productCategories: ['Medical Devices', 'Household Appliances', 'Battery', 'ITAV', 'Gaming Devices', 'IVD'],
    },
    defaultServicesOptions: ['Type Approval Support', 'Wireless Planning Commission', 'Product Safety Testing', 'BIS CRS Registration', 'Product Design Review For Medical Devices', 'CE-Technical File Construction', 'CE-Technical File Construction', 'EMC/EMI/Radio Test Support', 'Risk Assessment Review & Support'],

}


const reducer = (state, action) => {
    // console.log("addLabReducer", state);
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                currPage: state.currPage + 1,
            }
        case 'UPDATE_PAGE':
            return {
                ...state,
                currPage: action.payload,
            }
        case 'SET_PAGE_BACK':
            return {
                ...state,
                currPage: state.currPage > 1 ? state.currPage - 1 : 1,
            }
        case 'UPDATE_PAGE_BACK':
            return {
                ...state,
                currPage: action.payload,
            }

        case 'SET_BUSI_ACCOUNT':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        UserInfo: {
                            ...state.newLab.labInfo.UserInfo,
                            [action.payload.name]: action.payload.value,
                        },
                    }
                },
            }

        case 'SET_LAB_INFO':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }
        case 'SET_LAB_CONTACT_NUMBER':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        ContactNumber: {
                            ...state.newLab.labInfo.ContactNumber,
                            [action.payload.name]: action.payload.value,
                        },
                    },
                },
            }
        case 'SET_LAB_ADDRESS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        Address: {
                            ...state.newLab.labInfo.Address,
                            [action.payload.name]: action.payload.value,
                        },
                    },
                },
            }

        case 'SET_BUSINESS_CATEGORY':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        BusinessCategory: [...state.newLab.labInfo.BusinessCategory, action.payload],
                    },
                },
            }
        case 'UPDATE_BUSINESS_CATEGORY':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        BusinessCategory: action.payload,
                    },
                },
            }

        case 'SET_LAB_ACCREDITATION':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        LabAccreditation: action.payload,
                    },
                },
            }

        case 'SET_PRODUCT_CATEGORIES':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    ProductCategories: [...state.newLab.ProductCategories, action.payload],
                },
            }

        case 'SET_REGULATORY_APPROVALS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labApprovals: {
                        ...state.newLab.labApprovals,
                        RegulatoryApprovals: [...state.newLab.labApprovals.RegulatoryApprovals, action.payload],
                    },
                },
            }
        case 'SET_RA_MARKET':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labApprovals: {
                        ...state.newLab.labApprovals,
                        RA_Markets: [...state.newLab.labApprovals.RA_Markets, action.payload],
                    },
                },
            }
        case 'SET_AR_COVERED_MARKET':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labApprovals: {
                        ...state.newLab.labApprovals,
                        AR_CoveredMarkets: [...state.newLab.labApprovals.AR_CoveredMarkets, action.payload],
                    },
                },
            }
        case 'SET_CONSULTING_SERVICES':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labApprovals: {
                        ...state.newLab.labApprovals,
                        ConsultingServices: [...state.newLab.labApprovals.ConsultingServices, action.payload],
                    },
                },
            }
        case 'SET_CONSULTING_COVERED_MARKET':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labApprovals: {
                        ...state.newLab.labApprovals,
                        Consulting_CoveredMarkets: [...state.newLab.labApprovals.Consulting_CoveredMarkets, action.payload],
                    },
                },
            }


        case 'SET_TRAINING_SUBJECTS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    TrainingSubjects: [...state.newLab.TrainingSubjects, action.payload],
                },
            }
        case 'UPDATE_TRAINING_SUBJECTS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    TrainingSubjects: action.payload,
                },
            }

        case 'SET_TESTING_SERVICES':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    TestingServices: [...state.newLab.TestingServices, action.payload],
                },
            }
        case 'UPDATE_TESTING_SERVICES':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    TestingServices: action.payload,
                },
            }

        case 'SET_CHAMBERS_DETAILS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    Chambers: [...state.newLab.Chambers, action.payload],
                },
            }
        case 'UPDATE_CHAMBERS_DETAILS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    Chambers: action.payload,
                },
            }


        case 'SET_OTP':
            return {
                ...state,
                otp: action.payload,

            }




        case 'SET_REGIONS':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    labInfo: {
                        ...state.newLab.labInfo,
                        Region: action.payload,
                    }
                },
            }
        case 'SET_SERVICES':
            return {
                ...state,
                newLab: {
                    ...state.newLab,
                    Services: [...state.newLab.Services, action.payload],
                },
            }
        case 'SET_DEFAULT_SERVICES':
            return {
                ...state,
                defaultServicesOptions: [...state.defaultServicesOptions, action.payload]
            }


        case 'SET_DEFAULT_PRODUCT_CATEGORIES':
            return {
                ...state,
                defaultProductCategories: [...state.defaultProductCategories, action.payload],
            }
        default:
            return state;
    }
}

export default reducer;