import { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from "./EPEReducer";
// Data layer
export const EPEStateContext = createContext();

// Provider
export const EPEStateProvider = (props) => {
    return (
        <EPEStateContext.Provider value={useReducer(reducer, initialState)} >
            {props.children}
        </EPEStateContext.Provider>
    )
}

// this is used inside the components
export const useEPEStateValue = () => useContext(EPEStateContext);