import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./global.scss";
import "./index.css";

import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AddLabStateProvider } from "./context/addLabContext/addLabStateProvider";
import { useSelector } from "react-redux";

import { EPEStateProvider } from "./context/EPEContext/EPEStateProvider";
import { ConsultingStateProvider } from "./context/ConsultingContext/ConsultingStateProvider";
import { PCQStateProvider } from "./context/PCQContext/PCQStateProvider";
import { NPEStateProvider } from "./context/NPEContext/NPEStateProvider";
import Loading from "./components/utilities/Loading";

const DownloadReport = lazy(() => import("./components/Trf/DownloadReport"));
const ReportGeneration = lazy(() => import("./components/Trf/ReportGeneration"));
const UpgradeFilled = lazy(() => import("./components/Trf/UpgradeFilled"));
const AutomaticTag = lazy(() => import("./components/Trf/AutomaticTag"));
const RemoveTemplate = lazy(() => import("./components/Trf/RemoveTemplate"));
const FixTemplate = lazy(() => import("./components/Trf/FixTemplate"));
const Home = lazy(() => import("./components/Home/Home"));
const Auth = lazy(() => import("./components/Auth/Auth"));
const AddBusiness = lazy(() => import("./components/AddBusiness/AddBusiness"));
const LabInfo = lazy(() => import("./components/Labonfo/LabInfo"));
const Verify = lazy(() => import("./components/Auth/Verify"));
const ResetPassword = lazy(() => import("./components/Auth/ResetPassword"));
const HomeNav = lazy(() => import("./components/Nav/HomeNav"));
const Footer = lazy(() => import("./components/footer/Footer"));
const TrfNavbar = lazy(() => import("../src/components/Trf/TrfNavbar"));
const TrfFooter = lazy(() => import("./components/Trf/TrfFooter"));
const TrfMain = lazy(() => import("./components/Trf/TrfMain"));
const AdminControls = lazy(() => import("./components/Admin/AdminControls"));
const SearchSections = lazy(() =>
  import("./components/Sections/SearchSections")
);
const KnowledgeHub = lazy(() =>
  import("./components/knowledgeHub/KnowledgeHub")
);
const RegulatoryBlog = lazy(() =>
  import("./components/regulatoryBlog/RegulatoryBlog")
);
const WriteBlog = lazy(() =>
  import("./components/regulatoryBlog/writeBlog/WriteBlog")
);
const AuthNav = lazy(() => import("./components/Auth/AuthNav"));
const BlogPage = lazy(() =>
  import("./components/regulatoryBlog/insideEach/BlogPage")
);
const CreateCourseHomePage = lazy(() =>
  import("./components/knowledgeHub/createCourse/CreateCourseHomePage")
);
const GenrateCourse = lazy(() =>
  import("./components/knowledgeHub/createCourse/GenrateCourse")
);
const SingleCoursePage = lazy(() =>
  import("./components/knowledgeHub/courses/SingleCourse")
);
const KnowledgeHubNavBar = lazy(() =>
  import("./components/knowledgeHub/KnowledgeHubNavBar")
);
const InstructorPage = lazy(() =>
  import("./components/knowledgeHub/createCourse/InstructorPage")
);
const ViewCourse = lazy(() =>
  import("./components/knowledgeHub/viewCourse/ViewCourse")
);
const RegulatoryBlogNav = lazy(() =>
  import("./components/regulatoryBlog/RegulatoryBlogNav")
);
const WriterPage = lazy(() =>
  import("./components/regulatoryBlog/writeBlog/WriterPage")
);
const EditBlog = lazy(() =>
  import("./components/regulatoryBlog/writeBlog/EditBlog")
);
const RFQ = lazy(() => import("./components/Rfq/RFQ"));
const RFQFooter = lazy(() => import("./components/Rfq/RFQFooter"));
const RFQNavBar = lazy(() => import("./components/Rfq/RFQNavBar"));
const PreComplianceQuote = lazy(() =>
  import("./components/Rfq/preComplianceQuote/PreComplianceQuote")
);
const NewProductEvolution = lazy(() =>
  import("./components/Rfq/newProductEvolution/NewProductEvolution")
);
const ExistingProductEvolution = lazy(() =>
  import("./components/Rfq/existingProductEvolution/ExistingProductEvolution")
);
const Consulting = lazy(() => import("./components/Rfq/consulting/Consulting"));
const About = lazy(() => import("./components/About/About"));
const PartnerPortal = lazy(() =>
  import("./components/PartnerPortal/partnerportal")
);
const AddBus = lazy(() => import("./components/NewAddbussiness/AddBuss"));

// import PromotionPage from "./components/promotionPage/PromotionPage";

const OutLet = () => {
  const isAdmin = useSelector((state) => state?.user?.isAdmin) || false;

  return (
    <Routes>
      {/* <Route
          path="/promotion-page"
          element={
            <>
              <PromotionPage />
            </>
          }
        /> */}

      <Route
        path="/admin"
        element={
          isAdmin ? (
            <>
              <HomeNav
                sx={{
                  position: "relative",
                  "--color": "#2d2e2f",
                  "--bg-color": "white",
                }}
              />
              <AdminControls />
            </>
          ) : (
            <Navigate to={"/auth/login"} />
          )
        }
      />
      <Route
        path="/about"
        element={
          <>
            <HomeNav
              sx={{
                position: "relative",
                "--color": "#2d2e2f",
                "--bg-color": "white",
              }}
            />
            <About />
            <Footer />
          </>
        }
      />

      <Route
        path="/"
        element={
          <>
            <HomeNav
            // sx={{ position: 'relative', '--color': 'white', '--bg-color': '#2d2e2f' }}
            />
            <Home />
            <Footer />
          </>
        }
      />
      <Route
        path="/add-business-new"
        element={
          <>
            <HomeNav
              sx={{
                position: "relative",
                "--color": "#2d2e2f",
                "--bg-color": "white",
              }}
            />
            <AddBus />
            <Footer />
          </>
        }
      ></Route>

      <Route
        path="/"
        element={
          <>
            <HomeNav
            // sx={{ position: 'relative', '--color': 'white', '--bg-color': '#2d2e2f' }}
            />
            <Home />
            <Footer />
          </>
        }
      />

      <Route
        path="/rfq"
        element={
          <>
            <RFQNavBar />
            <RFQ />
            <RFQFooter />
          </>
        }
      />

      <Route
        path="/rfq/pre-compliance-quote"
        element={
          <>
            <PCQStateProvider>
              <RFQNavBar />
              <PreComplianceQuote />
              <RFQFooter />
            </PCQStateProvider>
          </>
        }
      />

      <Route
        path="/rfq/new-product-evolution"
        element={
          <>
            <NPEStateProvider>
              <RFQNavBar />
              <NewProductEvolution />
              <RFQFooter />
            </NPEStateProvider>
          </>
        }
      />

      <Route
        path="/rfq/existing-product-evolution"
        element={
          <>
            <RFQNavBar />
            <EPEStateProvider>
              <ExistingProductEvolution />
            </EPEStateProvider>
            <RFQFooter />
          </>
        }
      />

      <Route
        path="/rfq/consulting"
        element={
          <>
            <RFQNavBar />
            <ConsultingStateProvider>
              <Consulting />
            </ConsultingStateProvider>
            <RFQFooter />
          </>
        }
      />

      <Route
        path="/assurance-service"
        element={
          <ConsultingStateProvider>
            <HomeNav
              sx={{
                position: "relative",
                "--bg-color": "white",
                "--color": "#2d2e2f",
              }}
            />
            <Consulting />
            <Footer />
          </ConsultingStateProvider>
        }
      />

      <Route
        path="/knowledge-hub"
        element={
          <>
            <KnowledgeHubNavBar />
            <KnowledgeHub />
            <Footer />
          </>
        }
      />

      <Route
        path="/knowledge-hub/course/:courseId/:courseName/lecture/:lectureName"
        element={
          <>
            <ViewCourse />
          </>
        }
      />

      <Route
        path="/knowledge-hub/course/:courseName"
        element={
          <>
            <KnowledgeHubNavBar />
            <SingleCoursePage />
            <Footer />
          </>
        }
      />

      <Route
        path="/knowledge-hub/instructor/courses"
        element={
          <>
            <KnowledgeHubNavBar />
            <InstructorPage />
          </>
        }
      />

      <Route
        path="/knowledge-hub/instructor/create-title"
        element={<GenrateCourse />}
      />

      <Route
        path="/knowledge-hub/instructor/create/:courseId"
        element={
          <>
            <CreateCourseHomePage />
            <Footer />
          </>
        }
      />

      <Route
        path="/regulatory-blog"
        element={
          <>
            <RegulatoryBlogNav />
            <RegulatoryBlog />
            <Footer />
          </>
        }
      />
      <Route
        path="/regulatory-blog/blog/:blogId"
        element={
          <>
            <RegulatoryBlogNav />
            <BlogPage />
            <Footer />
          </>
        }
      />

      <Route
        path="/regulatory-blog/writer/blogs"
        element={
          <>
            <RegulatoryBlogNav />
            <WriterPage />
            <Footer />
          </>
        }
      />

      <Route
        path="/regulatory-blog/writer/write"
        element={
          <>
            <RegulatoryBlogNav />
            <WriteBlog />
            <Footer />
          </>
        }
      />

      <Route
        path="/regulatory-blog/writer/write/:blogId"
        element={
          <>
            <EditBlog />
            <Footer />
          </>
        }
      />

      <Route
        path="/labs/search"
        element={
          <>
            <HomeNav
              sx={{
                position: "sticky",
                "--color": "#2d2e2f",
                "--bg-color": "white",
              }}
            />
            <SearchSections />
            <Footer />
          </>
        }
      />

      <Route
        path="/lab/:labId"
        element={
          <>
            <HomeNav
              sx={{
                position: "relative",
                "--color": "#2d2e2f",
                "--bg-color": "white",
              }}
            />
            <LabInfo />
            <Footer />
          </>
        }
      />

      <Route
        path="/auth/:page"
        element={
          <>
            <Auth />
            <Footer />
          </>
        }
      />

      <Route
        path="/auth/:userId/verify/:tokenId"
        element={
          <>
            <AuthNav />
            <Verify />
            <Footer />
          </>
        }
      />

      <Route
        path="/auth/:userId/reset-password/:tokenId"
        element={
          <>
            <AuthNav />
            <ResetPassword />
            <Footer />
          </>
        }
      />
      <Route
        path="/partnerportal"
        element={
          <>
            <PartnerPortal />
          </>
        }
      />

      <Route
        path="/add-business"
        element={
          <>
            <AddLabStateProvider>
              <AddBusiness />
            </AddLabStateProvider>
          </>
        }
      />

      <Route
        path="/trf"
        element={
          <>
            <TrfNavbar />
            <TrfMain />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-download-report"
        element={
          <>
            <TrfNavbar />
            <DownloadReport />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-report-generation"
        element={
          <>
            <TrfNavbar />
            <ReportGeneration />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-upgrade-filled"
        element={
          <>
            <TrfNavbar />
            <UpgradeFilled />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-automatic-tag"
        element={
          <>
            <TrfNavbar />
            <AutomaticTag />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-remove-template"
        element={
          <>
            <TrfNavbar />
            <RemoveTemplate />
            <TrfFooter />
          </>
        }
      />
      <Route
        path="/trf-fix-report"
        element={
          <>
            <TrfNavbar />
            <FixTemplate />
            <TrfFooter />
          </>
        }
      />
    </Routes>
  );
};

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const faders = document.querySelectorAll(".fade-in");

    const appearOpt = {
      threshold: 0,
      rootMargin: "0px 0px -250px 0px",
    };

    const appearOnScroll = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target?.classList.remove("appear");
        } else {
          entry.target?.classList.add("appear");
          // appearOnScroll.unobserve(entry.target);
        }
      });
    }, appearOpt);

    faders.forEach((fader) => {
      appearOnScroll.observe(fader);
    });

    const navContainer = document.querySelector("[data-navbar]");

    // console.log(navContainer);
    const options = {
      threshold: 0,
      rootMargin: "-80px 0px 0px 0px",
    };

    const navLightTheme = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          navContainer?.classList.add("nav__dark");
        } else {
          navContainer?.classList.remove("nav__dark");
        }
      });
    }, options);

    const forntContainer = document.querySelector(".main-swiper");
    if (forntContainer) {
      navLightTheme.observe(forntContainer);
    }

    // const navChanger = document.querySelector('#nav-changer');

    // if (navChanger) {
    //   navLightTheme.observe(navChanger)
    // }

    return () => {
      if (forntContainer) {
        navLightTheme.unobserve(forntContainer);
      }
      // if (navChanger) {
      //   navLightTheme.unobserve(navChanger);
      // }
    };
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <OutLet />
    </Suspense>
  );
};

export default App;

// function wait(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }
