import './Loading.css'

const Loading = () => {
    const randomText = ['Discovering the perfect lab for your product has never been quicker!', 'Get ready to be amazed as we speed through the lab-finding maze!', 'Discovering labs has become a breeze, like a thrilling treasure hunt!', 'Finding labs is now simple work']
    const randomIdx = Math.floor(Math.random() * 4);


    return (
        <div className="loading-center">
            <p className='text-center'>{randomText[randomIdx]}</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loading