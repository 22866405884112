export const initialState = {
    currPage: 1,
    error: false,
    newEPEId: '',
    newEPE: {
        RfqType: "Existing Product Evolution",
        UserInfo: {},
        Address: {},
        Description: '',
        UploadedDocs: {},
        IMCountries: [],
        Emails: [],
        filledEmailsBox: [],
    },
    defaults: {
        pageHeader: ["Existing Certified/Evaluated product", "Existing Certified/Evaluated product", "Submit Your Quote Request"],
        defaultIMCountries: ['AUSTRALIA', 'SWITZERLAND', 'BRAZIL', 'KOREA', 'CANADA', 'MEXICO', 'CHINA', 'SAUDI ARABIA', 'DENMARK', 'SINGAPORE', 'EUROPEAN UNION', 'JAPAN', 'INDIA', 'UNITED KINGDOM'],
    },

}


const reducer = (state, action) => {
    // console.log("addLabReducer", action);
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_EPE_ID':
            return {
                ...state,
                newEPEId: state.payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                currPage: state.currPage + 1,
            }
        case 'UPDATE_PAGE':
            return {
                ...state,
                currPage: action.payload,
            }
        case 'SET_PAGE_BACK':
            return {
                ...state,
                currPage: state.currPage > 1 ? state.currPage - 1 : 1,
            }
        case 'UPDATE_PAGE_BACK':
            return {
                ...state,
                currPage: action.payload,
            }


        case 'SET_GEN_INFO':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    UserInfo: {
                        ...state.newEPE.UserInfo,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'SET_ADDRESS':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    Address: {
                        ...state.newEPE.Address,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'UPDATE_IM_COUNTRIES':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    IMCountries: [
                        ...state.newEPE.IMCountries,
                        action.payload,
                    ]
                },
            }
        case 'SET_IM_COUNTRIES':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    IMCountries: action.payload
                },
            }
        case 'SET_DESC':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    Description: action.payload
                },
            }

        case 'SET_STATE_COM_DOCS':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    UploadedDocs: {
                        ...state.newEPE.UploadedDocs,
                        [action.payload.name]: {
                            ...state.newEPE.UploadedDocs[action.payload.name],
                            [action.payload.fieldName]: action.payload.value
                        }
                    }
                },
            }

        case 'SET_EMAILS':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    Emails: action.payload,
                },
            }

        case 'SET_EMAILS_BOXES':
            return {
                ...state,
                newEPE: {
                    ...state.newEPE,
                    filledEmailsBox: action.payload,
                },
            }

        default:
            return state;
    }
}

export default reducer;