export const initialState = {
    currPage: 1,
    error: false,
    newPCQId: 'fdgfg',
    newPCQ: {
        RfqType: 'Pre Compliance Quote',
        UserInfo: {},
        Address: {},
        Description: '',
        UploadedDocs: {},
        TestReq: [],
        Emails: [],
        filledEmailsBox: [],
    },
    defaults: {
        pageHeader: ["Pre Compliance/Engineering Quote", "Pre Compliance/Engineering Quote", "Submit Your Quote Request"],
        defaultTestReq: ["SAFETY",
            "LASER SAFETY",
            "MEDICAL SAFETY",
            "IP TESTING",
            "EMC 3m CHAMBER TIME",
            "EMC 10m CHAMBER TIME",
            "ENVIRONMENTAL CHAMBER TIME"]
    },

}


const reducer = (state, action) => {
    // console.log("addLabReducer", action);
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_PCQ_ID':
            return {
                ...state,
                newPCQId: state.payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                currPage: state.currPage + 1,
            }
        case 'UPDATE_PAGE':
            return {
                ...state,
                currPage: action.payload,
            }
        case 'SET_PAGE_BACK':
            return {
                ...state,
                currPage: state.currPage > 1 ? state.currPage - 1 : 1,
            }
        case 'UPDATE_PAGE_BACK':
            return {
                ...state,
                currPage: action.payload,
            }


        case 'SET_GEN_INFO':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    UserInfo: {
                        ...state.newPCQ.UserInfo,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'SET_ADDRESS':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    Address: {
                        ...state.newPCQ.Address,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }
        case 'SET_DESC':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    Description: action.payload
                },
            }

        case 'SET_STATE_COM_DOCS':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    UploadedDocs: {
                        ...state.newPCQ.UploadedDocs,
                        [action.payload.name]: {
                            ...state.newPCQ.UploadedDocs[action.payload.name],
                            [action.payload.fieldName]: action.payload.value
                        }
                    }
                },
            }

        case 'SET_EMAILS':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    Emails: action.payload,
                },
            }

        case 'SET_EMAILS_BOXES':
            return {
                ...state,
                newPCQ: {
                    ...state.newPCQ,
                    filledEmailsBox: action.payload,
                },
            }

        default:
            return state;
    }
}

export default reducer;