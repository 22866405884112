import { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from "./PCQReducer";
// Data layer
export const PCQStateContext = createContext();

// Provider
export const PCQStateProvider = (props) => {
    return (
        <PCQStateContext.Provider value={useReducer(reducer, initialState)} >
            {props.children}
        </PCQStateContext.Provider>
    )
}

// this is used inside the components
export const usePCQStateValue = () => useContext(PCQStateContext);