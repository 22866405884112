export const initialState = {
    currPage: 1,
    error: false,
    newNPEId: '',
    newNPE: {
        RfqType: 'New Product Evaluation',

        UserInfo: {},
        Address: {},

        Description: '',
        UploadedDocs: {},
        IMCountries: [],

        ICountries: [],

        ServicesReq: [],

        Emails: [],
        filledEmailsBox: [],

        MedicalSafety: {},
        MedicalEMC: {},
        HomeHealthcare: {},
        InfusionPump: {},
    },
    defaults: {
        pageHeader: ["New Product Evaluation", "New Product Evaluation", "New Product Evaluation", "Submit Your Quote Request"],
        defaultIMCountries: [
            "AUSTRALIA",
            "JAPAN",
            "BRAZIL",
            "KOREA",
            "CANADA",
            "MEXICO",
            "CHINA",
            "SAUDI ARABIA",
            "DENMARK",
            "SINGAPORE",
            "EUROPEAN UNION",
            "SWITZERLAND",
            "UNITED STATES",
            "OTHER"
        ],
        defaultServicesReq: [
            'NRTL Listing for North America',
            'Field Label Evaluation',
            'NRTL Classification for North America',
            'Design Review',
            'NRTL Recognition for North America',
            'EMC Testing',
            'FDA ASCA Pilot Program',
            'ROHS Evaluation',
            'EU Test Reports',
            'Performance Testing',
            'IEC Test Reports',
            "Witness Manufacturer's Testing",
            "CB Scheme Evaluation",
            "Testing at Manufacturer's Premises ",
            "Limited Production Certificate",
        ],
        INTENDEDCOUNTRIES: [
            "AUSTRALIA",
            "JAPAN",
            "BRAZIL",
            "KOREA",
            "CANADA",
            "MEXICO",
            "CHINA",
            "SAUDI ARABIA",
            "DENMARK",
            "SINGAPORE",
            "EUROPEAN UNION",
            "SWITZERLAND",
            "UNITED STATES",
        ],
        defaultServicesReqQues: [
            "Would you like your product evaluated to the first amendment of 60601-1, Third Edition?",
            "NRTL Classification for North America :",
            "Do you have a risk management file for your product that is compliant with the requirements of ISO 14971?",
            "Has your ISO 14971-1 Risk Management Process been reviewed by DC?",
            "Usability File - Would you like DC to evaluate your Usability Engineering File against IEC 62366-1: 2015?",
            "If yes to a usability file review which standard would you like DC to use:",
            "Does your product have alarms incorporated into the medical device 60601-1-8?",
            "Will your product be used in a home health care environment 60601-1-11?",
            "Does your product incorporate the use of a laser 60825?",
            "Does your product use batteries? If yes what kind of battery is used:",
            "Does your product incorporate the use of LEDs as luminaires/lamp IEC 62471?",
            "Will your product be used in a emergency medical services environment 60601-1-12?",
            "Is the product rated IP rated for protection against solid foreign objects",
            "Is the product rated IP rated for protection against water"
        ]

    }
}


const reducer = (state, action) => {
    // console.log("addLabReducer", action);
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case 'SET_NPE_ID':
            return {
                ...state,
                newNPEId: state.payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                currPage: state.currPage + 1,
            }
        case 'UPDATE_PAGE':
            return {
                ...state,
                currPage: action.payload,
            }
        case 'SET_PAGE_BACK':
            return {
                ...state,
                currPage: state.currPage > 1 ? state.currPage - 1 : 1,
            }
        case 'UPDATE_PAGE_BACK':
            return {
                ...state,
                currPage: action.payload,
            }


        case 'SET_GEN_INFO':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    UserInfo: {
                        ...state.newNPE.UserInfo,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }

        case 'SET_ADDRESS':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    Address: {
                        ...state.newNPE.Address,
                        [action.payload.name]: action.payload.value,
                    }
                },
            }


        // page 2
        case 'UPDATE_IM_COUNTRIES':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    IMCountries: [
                        ...state.newNPE.IMCountries,
                        action.payload,
                    ]
                },
            }
        case 'SET_IM_COUNTRIES':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    IMCountries: action.payload
                },
            }
        case 'SET_DESC':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    Description: action.payload
                },
            }

        case 'SET_EMAILS':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    Emails: action.payload,
                },
            }
        case 'SET_EMAILS_BOXES':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    filledEmailsBox: action.payload,
                },
            }


        case 'SET_MEDICAL_EMC':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    MedicalEMC: {
                        ...state.newNPE.MedicalEMC,
                        [action.payload.name]: action.payload.value
                    }
                },
            }
        case 'SET_MEDICAL_EMC_OBJECT':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    MedicalEMC: {
                        ...state.newNPE.MedicalEMC,
                        [action.payload.name]: {
                            ...state.newNPE.MedicalEMC[action.payload.name],
                            [action.payload.key]: action.payload.value
                        }
                    }
                },
            }


        case 'SET_HOME_HEALTHCARE':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    HomeHealthcare: {
                        ...state.newNPE.HomeHealthcare,
                        [action.payload.name]: action.payload.value
                    }
                },
            }
        case 'SET_HOME_HEALTHCARE_OBJECT':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    HomeHealthcare: {
                        ...state.newNPE.HomeHealthcare,
                        [action.payload.name]: {
                            ...state.newNPE.HomeHealthcare[action.payload.name],
                            [action.payload.key]: action.payload.value
                        }
                    }
                },
            }


        case 'SET_INFUSION_PUMP':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    InfusionPump: {
                        ...state.newNPE.InfusionPump,
                        [action.payload.name]: action.payload.value
                    }
                },
            }
        case 'SET_INFUSION_PUMP_OBJECT':
            return {
                ...state,
                newNPE: {
                    ...state.newNPE,
                    InfusionPump: {
                        ...state.newNPE.InfusionPump,
                        [action.payload.name]: {
                            ...state.newNPE.InfusionPump[action.payload.name],
                            [action.payload.key]: action.payload.value
                        }
                    }
                },
            }

        default:
            return state;
    }
}

export default reducer;